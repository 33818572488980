<template>
  <div class="other-warehousing">
    <PageHeader title="其他入库" />
    <div class="searchView flex a-center m-tb-10 j-between p-lr-20">
      <div class="flex j-start">
        <div class="flex a-center">
          <div class="f14 p-right-10">供应商</div>
          <el-select v-model="form.SellCompanyID" filterable size="mini" style="width:150px" placeholder="请选择">
            <el-option
              v-for="item in CompanyAddress"
              :key="item.IDX"
              :label="item.text"
              :value="item.IDX"
            />
          </el-select>
        </div>
        <div class="flex a-center p-left-10">
          <div class="f14 p-right-10">入库仓库</div>
          <el-select v-model="form.Location" size="mini" style="width:150px" placeholder="请选择">
            <el-option
              v-for="(item,index) in BuyersTerminal"
              :key="index"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
        <div class="flex m-left-10  a-center">
          <div class="left f14 p-right-10">入库类型</div>
          <el-select v-model="form.InStockType" size="mini" style="width:150px" placeholder="请选择">
            <el-option
              v-for="item in ReceivingUnit"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
      </div>
      <div class="flex">
        <!-- <div class="out   p-lr-10 p-tb-5 min-border flex a-center f12 m-lr-5 pointer cursor">
          <i class="el-icon-upload2 f15" />
          <div>导入</div>
        </div> -->
        <div class="ModelBttton-white-30 m-right-10" @click="addGoods">
          <i class="el-icon-zoom-in f15 m-right-5" />
          <span>添加产品</span>
        </div>
        <div class="ModelBttton-white-30 m-right-10" @click="choiceImg">
          <i class="el-icon-brush f15 m-right-5" />
          <span>上传附件</span>
          <input ref="filElem" style="display:none" type="file" class="upload-file" @change="getFile">
        </div>
        <div class="ModelBttton-blue-30" @click="chuku">
          <div>确认入库</div>
        </div>
      </div>
    </div>
    <div class="p-lr-20">
      <el-table
        :data="form.stockOutDeliveryByArtificials"
        class="TableModel20"
        border
        height="calc(100vh - 330px)"
        row-key="index"
        default-expand-all
        :span-method="arraySpanMethod"
        :header-cell-style="headerCStyle"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        @select-all="selectAll"
        @select="select"
      >
        <el-table-column
          type="selection"
          width="40"
        />
        <el-table-column align="center" property="InvCode" label="物料编码" show-overflow-tooltip />
        <el-table-column align="center" property="InvName" label="物料名称" show-overflow-tooltip />
        <el-table-column align="center" property="BrandName" label="物料品牌" show-overflow-tooltip />
        <el-table-column align="center" property="MaterialTypeName" label="物料类别" show-overflow-tooltip />
        <el-table-column align="center" property="Specification" label="物料规格" show-overflow-tooltip />
        <el-table-column align="center" property="StockUnitN" label="物料单位" show-overflow-tooltip />
        <!-- <el-table-column align="center" property="LotNum" label="物料批号" /> -->
        <el-table-column align="center" property="LotNum" label="批号/序列号" width="150">
          <template slot-scope="scope">
            <el-input v-model="scope.row.LotNum" size="mini" />
          </template>
        </el-table-column>
        <el-table-column align="center" property="ExpireDate" width="200" label="效期">
          <template slot-scope="scope">
            <el-date-picker
              v-model="scope.row.ExpireDate"
              type="date"
              placeholder="选择日期"
              size="mini"
              style="width:150px"
              value-format="yyyy-MM-dd"
            />
            <!-- <el-input v-model="scope.row.ExpiredDate" size="mini" /> -->
          </template>
        </el-table-column>
        <el-table-column align="center" property="InStockNum" label="入库数量">
          <template slot-scope="scope">
            <el-input v-model="scope.row.InStockNum" size="mini" />
          </template>
        </el-table-column>
        <el-table-column align="center" property="Price" label="采购单价">
          <template slot-scope="scope">
            <el-input v-model="scope.row.Price" size="mini" />
          </template>
        </el-table-column>
        <el-table-column align="center" property="ProductDate" label="生产日期" width="200">
          <template slot-scope="scope">
            <!-- <el-input v-model="scope.row.ProductDate" /> -->
            <el-date-picker
              v-model="scope.row.ProductDate"
              type="date"
              placeholder="选择日期"
              size="mini"
              style="width:150px"
              value-format="yyyy-MM-dd"
            />
          </template>
        </el-table-column>
        <el-table-column align="center" property="ExternalPacking" width="100" label="外包装">
          <template slot-scope="scope">
            <el-select v-model="scope.row.ExternalPacking" size="mini" placeholder="完整">
              <el-option
                v-for="(item,index) in ExternalPacking"
                :key="index"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </template>

        </el-table-column>
        <el-table-column align="center" property="Temperature" width="100" label="到货温度">
          <template slot-scope="scope">
            <el-select v-model="scope.row.Temperature" size="mini" placeholder="合格">
              <el-option
                v-for="(item,index) in Temperature"
                :key="index"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </template>

        </el-table-column>
        <el-table-column align="center" property="DINum" label="物料DI码">
          <template slot-scope="scope">
            <el-input v-model="scope.row.DINum" size="mini" />
          </template>
        </el-table-column>
        <!-- <el-table-column align="center" property="StockCode" label="库存编号" />
        <el-table-column align="center" property="MaterialStockSellId" label="物料库位ID" /> -->
        <el-table-column fixed="right" prop="a" align="center" label="操作" width="50">
          <template slot-scope="score">
            <el-popover
              placement="left"
              trigger="hover"
              :width="100"
              popper-class="test_pop_view"
            >
              <div class="DropDownList">
                <div class="OperationButton" @click="uploadFile(score.$index, score.row)">
                  <span class="input_file">
                    <el-link :underline="false"><i class="el-icon-paperclip p-right-10" /> 上传附件</el-link>
                    <input ref="filElem1" type="file" class="NoShow upload-file" @change="getFile1">
                  </span>
                </div>
                <div class="OperationButton">
                  <el-link :underline="false" @click.stop="delChaif(score.$index, score.row)"><i class="el-icon-remove-outline p-right-10" /> 移除</el-link>
                </div>
                <div class="OperationButton" @click.stop="chaifen(score.$index, score.row)">
                  <el-link :underline="false"><i class="el-icon-connection p-right-10" /> 拆分</el-link>
                </div>
              </div>
              <i slot="reference" class="el-icon-more" />
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <!-- <div class="remake_view flex j-between m-top-20 p-right-20">
        <div id="remake_view_left" class="remake_view_left  f14  flex">
          <div style="width:80px;line-height:50px" class=" center">备注</div>
          <el-input v-model="form.Remarks" />
        </div>
      </div> -->
    </div>
    <el-dialog class="DepartmentBox" :visible.sync="visible" custom-class="dialog_view" :show-close="false">
      <div class="PageHeader1 min-border-bottom flex j-between">
        <span class="Name">添加商品</span>
        <i class="el-dialog__close el-icon el-icon-close Close" @click="() => visible = false" />
      </div>
      <div class="search searchView flex j-around p-top-20 m-bottom-20">
        <div class="flex m-right-20" style="flex: 1">
          <el-input
            v-model="formSearch.KeyWords"
            size="medium"
            placeholder="可通过物料号、物料名称、品牌、物料类型、原厂编号、di码搜索"
            class="rule-input-edit"
          >
            <template slot="prepend">
              <div class="pointer" @click="() => visibl1e = true">
                <i class="el-icon-s-operation" />
                <span>高级搜索</span>
              </div>
            </template>
            <div
              slot="append"
              @click="searchAd"
            >
              搜 索
            </div>
          </el-input>
        </div>
        <el-button style="font-size:14px" size="mini" icon="el-icon-folder-add" round @click="addGoodS">批量添加</el-button>
      </div>
      <el-table
        ref="testTable"
        border
        :data="tableData"
        row-key="index"
        max-height="500px"
        @select-all="selectAll"
        @select="select"
      >
        <el-table-column
          type="selection"
          width="40"
          align="center"
          :selectable="checkboxInit"
        />
        <el-table-column align="left" property="InvCode" label="物料编码" show-overflow-tooltip width="80" />
        <el-table-column align="left" property="InvName" label="物料名称" show-overflow-tooltip />
        <el-table-column align="left" property="BrandName" label="物料品牌" show-overflow-tooltip />
        <el-table-column align="left" property="MaterialTypeName" label="物料类别" show-overflow-tooltip />
        <el-table-column align="left" property="Specification" label="物料规格" show-overflow-tooltip />
        <el-table-column align="left" property="StockUnitN" label="物料单位" show-overflow-tooltip width="80" />
        <el-table-column align="left" property="StockUnitN" label="是否盘点中" show-overflow-tooltip width="80">
          <template slot-scope="scope">
            {{ scope.row.isCorrecting === 1 ? '是':'否' }}
          </template>
        </el-table-column>

        <el-table-column align="center" property="a" fixed="right" label="操作" width="50">
          <template v-if="!scope.row.disabled && scope.row.isCorrecting !== 1" slot-scope="scope">
            <el-link :underline="false" @click="addGoodOne(scope.row)">添加</el-link>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-container m-bottom-20">
        <el-pagination
          :ref="paginationName"
          :total="tableTotalSize"
          :pager-count="pagerCount"
          :page-size="tablePageSize"
          :background="tableBackground"
          layout="prev, pager, next, jumper"
          @current-change="handleCurrentChange($event)"
        />
      </div>
    </el-dialog>
    <Adsearch :form="formSearch" :ad-search-form="adSearchForm" :show="visibl1e" @cancel="(e) => visibl1e = e" @searchAd="searchAd" />
  </div>
</template>
<script>
import Index from '@/minxin/management/other-warehousing'
export default Index
</script>
<style scoped lang="scss">
@import "@/style/search.scss";
.dialog_view{
    position: relative;
}
 .PageHeader1{
    width: 100%;
    height: 50px;
    background-color: #fff;
    position: absolute;
    left: 0;
    top:0;
    display: flex;
    color:   #006fda;
    &::after{
        content: '';
        width: 3px;
        height:50%;
        background-color:  #006fda;
        position: absolute;
        left: 0;
        top: 25%;
    }
 }

</style>
