/* eslint-disable keyword-spacing */
import PageHeader from '@/components/PageHeader'
import Adsearch from '@/components/Adsearch'
import tableMixin from '@/minxin/table-minxin'
const Index = {
  name: 'other-warehousing',
  components: {
    PageHeader,
    Adsearch
  },
  mixins: [tableMixin],
  mounted() {
    this.GetCompanyNameQueryListJson()
    this.QueryInStockList()
  },
  watch: {
    tableData(a) {
      a.map(item => {
        if(item.isCorrecting === 1) {
          this.$nextTick(() => {
            this.$set(item, 'disabled', true)
          })
        }
      })
    }
  },
  data() {
    return {
      tablePageSize: 5,
      visible: false,
      visibl1e: false,
      adSearchForm: [
        { name: '物料编码', value: 'MaterialInvCode' },
        { name: '物料品牌', value: 'BrandName' },
        { name: '物料名称', value: 'MaterialName' },
        { name: '物料类型', value: 'MaterialType' },
        { name: '物料DI码', value: 'DINum' }
      ],
      formSearch: {
        StockFiler: 1,
        KeyWords: '',
        MaterialInvCode: '', // 物料编码
        BrandName: '', // 物料品牌
        MaterialName: '', // 物料名称
        MaterialType: '', // 物料类型
        DINum: '', // 物料DI码
        PageIndex: '',
        PageSize: ''
      },
      formSearch1: {
        StockFiler: 1,
        KeyWords: '',
        MaterialInvCode: '', // 物料编码
        BrandName: '', // 物料品牌
        MaterialName: '', // 物料名称
        MaterialType: '', // 物料类型
        DINum: '', // 物料DI码
        PageIndex: '',
        PageSize: ''
      },
      //  出库发货 提交数据
      form: {
        SellCompanyID: '', // 售达方
        Location: '', // 送达方
        Remarks: '', // 备注
        InStockType: '', // 出库单类型
        FileUrl: '', // 附件地址
        stockOutDeliveryByArtificials: [], // 物料列表
        ParamsList: []
      },
      selects: [],
      // 供应商
      CompanyAddress: [],
      // 入库仓库
      BuyersTerminal: [],
      isBBB: false,
      // 入库类型
      ReceivingUnit: [
        {
          value: 1,
          label: '手工入库'
        }, {
          value: 2,
          label: '采购入库'
        }, {
          value: 3,
          label: '生产入库'
        }, {
          value: 4,
          label: '退货入库'
        }, {
          value: 5,
          label: '调拨入库'
        },
        {
          value: 6,
          label: '盘点入库'
        },
        {
          value: 7,
          label: '归还入库'
        }
      ],
      ExternalPacking: [
        { label: '完整', value: 1 },
        { label: '不完整', value: 0 }
      ],
      Temperature: [
        { label: '合格', value: 1 },
        { label: '不合格', value: 0 }
      ],
      arrForm: [],
      row: 0
    }
  },
  methods: {
    selectAll(e) {
      this.selects = e
    },
    select(row) {
      this.selects = row
    },
    async  searchAd() {
      this.formSearch.PageIndex = 1
      this.formSearch.PageSize = this.tablePageSize
      console.log(' this.formSearch', this.formSearch)
      const response = await this.$api.QueryMaterials(this.formSearch)
      this.tableData = response.Message
      this.tableTotalSize = response.Total
      // this.formSearch.KeyWords = null
      this.visibl1e = false
    },
    // GetCompanyNameQueryListJson 买方终端 收货单位地址 companyType
    async GetCompanyNameQueryListJson(companyName) {
      const response = await this.$api.GetCompanyNameQueryListJson({
        companyType: 1,
        companyName: companyName || ''
      })
      this.CompanyAddress = response.Message
      console.log(response)
    },
    // 批量添加
    addGoodS() {
      if (!this.selects.length) return this.$message.warning('请选择你要添加的物料')
      this.form.stockOutDeliveryByArtificials = this.selects
      this.form.stockOutDeliveryByArtificials.map(item => {
        this.$set(item, 'StockOutNum')
        this.$set(item, 'spanRow', 1)
        this.$set(item, 'ExpireDate', null)

        this.$set(item, 'ExternalPacking', 1)
        this.$set(item, 'Temperature', 1)
      })
      this.visibl1e = false
      this.visible = false
      this.selects = []
    },
    // 单个添加
    addGoodOne(row) {
      this.form.stockOutDeliveryByArtificials.push(row)
      this.form.stockOutDeliveryByArtificials.map(item => {
        this.$set(item, 'StockOutNum')
        this.$set(item, 'spanRow', 1)
        this.$set(item, 'ExpireDate', null)
        this.$set(item, 'ExternalPacking', 1)
        this.$set(item, 'Temperature', 1)
      })
      this.visibl1e = false
      this.visible = false
    },
    // 添加商品弹出框
    addGoods() {
      this.formSearch = this.formSearch1
      this.getTableDataAsync()
      this.visible = true
    },
    // 删除拆分
    delChaif(index, rows) {
      // if(this.form.stockOutDeliveryByArtificials.length >= 2) {
      //   if(index !== this.form.stockOutDeliveryByArtificials.length - 1) {
      //     if(this.form.stockOutDeliveryByArtificials[index].IDX === this.form.stockOutDeliveryByArtificials[index + 1].IDX) {
      //       this.form.stockOutDeliveryByArtificials[index + 1].isNoSet = false
      //     }
      //   }
      // }
      const arr = this.form.stockOutDeliveryByArtificials.filter(item => item.IDX === rows.IDX)
      if(arr.length <= 0) this.form.stockOutDeliveryByArtificials[index].isNoSet = false
      if(arr.length === 2) {
        arr.forEach(item => {
          item.isNoSet = false
        })
      }
      // if(index !== this.form.stockOutDeliveryByArtificials.length - 1) {
      //   if(this.form.stockOutDeliveryByArtificials[index].IDX === this.form.stockOutDeliveryByArtificials[index + 1].IDX) {
      //     this.form.stockOutDeliveryByArtificials[index + 1].isNoSet = false
      //   }
      // }
      this.$nextTick(() => {
        this.form.stockOutDeliveryByArtificials.splice(index, 1)
        this.setspanRow()
      })
    },
    // 删除某个节点
    removeDom(index, row) {
      console.log('要移除的row', row)
      this.form.stockOutDeliveryByArtificials.forEach((item, index) => {
        if (item.IDX === row.IDX) {
          this.form.stockOutDeliveryByArtificials.splice(index, 1)
          return this.$message.success('移除成功')
        }
      })
    },
    checkboxInit1(row, index) {
      if (row.isNoSet) {
        return 0// 不可勾选
      } else {
        return 1// 可勾选
      }
    },
    checkboxInit(row, index) {
      if (row.disabled) {
        console.log(312312231312312)
        return 0// 不可勾选
      } else {
        return 1// 可勾选
      }
    },
    // 获取物料列表
    async getTableDataAsync(page, pageSize) {
      this.formSearch.PageIndex = page || 1
      this.formSearch.PageSize = this.tablePageSize
      this.tableData = []
      const response = await this.$api.QueryMaterials(this.formSearch)
      this.tableData = response.Message
      this.tableTotalSize = response.Total

      if (this.form.stockOutDeliveryByArtificials.length > 0) {
        this.tableData.map(item => {
          this.form.stockOutDeliveryByArtificials.map(item1 => {
            if (item.IDX === item1.IDX) {
              this.$nextTick(() => {
                this.$refs.testTable.toggleRowSelection(item, true)
                this.$set(item, 'disabled', true)
              })
            }
          })
        })
      }
      console.log(response)
      return response
    },
    // 组成提交数据
    setFormData(row) {
      const obj = {
        Url: '',
        FileName: '',
        Price: '',
        DINum: '', // DINum码
        IDX: '', // 明细IDX,
        LotNum: '', // 入库批号/序列号,
        ProductDate: '', // 生产日期,
        ExpireDate: '', // 效期,
        InStockNum: '', // 入库数量,
        ExternalPacking: '', // 外包装,
        Temperature: '', // 到货温度,
        StockID: '' // 入库仓库
      }
      for (const key in obj) {
        obj[key] = row[key]
      }
      // const obj = {
      //   IDX: '', // 明细IDX,
      //   LotNum: '', // 入库批号/序列号,
      //   ProductDate: '', // 生产日期,
      //   ExpiredDate: '', // 效期,
      //   InStockNum: '', // 入库数量,
      //   ExternalPacking: '', // 外包装,
      //   Temperature: '', // 到货温度
      //   Price: '', // 价格,
      //   Url: '', // 附件地址
      //   DetailID: ''
      // }
      // for (const key in obj) {
      //   obj[key] = row[key]
      // }
      // if (row.isNoSet) { delete obj.IDX }
      // if (!row.isNoSet) { delete obj.DetailID }
      // if (row.children && row.children.length > 0) {
      //   row.children.forEach(item2 => {
      //     this.setFormData(item2)
      //   })
      // }
      this.form.ParamsList.push(obj)
    },
    // 判断是否超出
    isAllNums(row) {
      if (row.ReceiveNum * 1 >= row.Num * 1) return false
      if (row.ReceiveNum * 1 + row.InStockNum * 1 >= row.Num * 1) return false
      if (row.children && row.children.length > 0) {
        // eslint-disable-next-line no-unused-vars
        let childrenNum = 0
        row.children.forEach(item => {
          childrenNum += item.InStockNum * 1
        })
        if (childrenNum * 1 + row.ReceiveNum * 1 + row.InStockNum * 1 >= row.Num * 1) return false
        // if (row.Num === row.ReceiveNum) return false
      }
      return true
    },
    // 拆分
    chaifen(index, row) {
      if (!this.isAllNums(row)) return this.$message.warning('已入数量已经等于总数量')
      const temp = JSON.parse(JSON.stringify(row))
      temp.LotNum = ''
      temp.ProductDate = ''
      temp.ExpireDate = ''
      temp.InStockNum = ''
      temp.ExternalPacking = ''
      temp.Temperature = ''
      temp.DINum = ''
      temp.isNoSet = true
      this.form.stockOutDeliveryByArtificials.splice(index + 1, 0, temp)
      this.setspanRow()
      // if (!this.isAllNums(row)) return this.$message.info('已入数量已经等于总数量')
      // // if (row.Num === row.ReceiveNum) return this.$message.info('已入数量已经等于总数量')
      // console.log(index)
      // let temp = {}
      // temp = Object.assign(temp, row)
      // temp.IDX = Date.now()
      // temp.isNoSet = true
      // temp.InStockNum = 0
      // delete temp.children
      // if (row.children && row.children.length > 0) {
      //   return row.children.push(temp)
      // }
      // this.$set(row, 'children', [])
      // console.log(temp)
      // row.children.push(temp)
      // console.log(this.tableData)
    },
    // 处理数据
    setspanRow() {
      if(this.form.stockOutDeliveryByArtificials.length === 1) return this.form.stockOutDeliveryByArtificials[0].spanRow = 1

      this.form.stockOutDeliveryByArtificials.forEach(item => {
        item.spanRow = 0
        this.form.stockOutDeliveryByArtificials.forEach((ele) => {
          if (item.IDX === ele.IDX) {
            item.spanRow += 1
          }
        })
      })
    },
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      const _arr = [1, 2, 3, 4, 5, 6] // 要合并的列
      if (_arr.indexOf(columnIndex) > -1) {
        if (!row.isNoSet) {
          return [row.spanRow, 1]
        } else {
          return [0, 0]
        }
      }
    },
    // 入库仓库 QueryInStockList
    QueryInStockList() {
      this.$api.QueryInStockList().then(res => {
        this.BuyersTerminal = res.Message
      })
    },
    chuku() {
      if(this.isBBB) return
      this.form.ParamsList = []
      if (this.selects.length <= 0) return this.$message.warning('请选择你要入库的物料')
      const arr = this.selects.filter(item => !item.InStockNum)
      if(arr.length > 0) return this.$message.error('请检查入库数量')
      const arr3 = this.selects.filter(item => !item.LotNum)
      if(arr3.length > 0) return this.$message.error('请检查入库批号')
      // const arr1 = this.selects.filter(item => !item.Price)
      // if(arr1.length > 0) return this.$message.error('请检查单价')
      const arr2 = this.selects.filter(item => !item.ExpireDate)
      if(arr2.length > 0) return this.$message.error('请检查效期')
      this.selects.forEach(item => {
        this.setFormData(item)
      })
      if(!this.form.InStockType) return this.$message.error('入库类型不能为空')
      // if(!this.form.Location) return this.$message.error('入库仓库不能为空')
      // if(!this.form.SellCompanyID) return this.$message.error('供应商不能为空')
      console.log(this.form)
      this.isBBB = true

      this.$api.OtherInStockSave(this.form).then(res => {
        if(res.RetCode === '0') {
          // this.getTableDataAsync()
          this.form = {
            SellCompanyID: '', // 售达方
            Location: '', // 送达方
            Remarks: '', // 备注
            InStockType: '', // 出库单类型
            FileUrl: '', // 附件地址
            stockOutDeliveryByArtificials: [], // 物料列表
            ParamsList: []
          }
          setTimeout(() => {
            this.isBBB = false
          }, 1000)

          return this.$message.success('提交成功')
        }else{
          setTimeout(() => {
            this.isBBB = false
          }, 1000)
          return this.$message.error('提交失败' + res.RetMsg)
        }
      })
    },
    // 上传附件
    uploadFile(index, row) {
      this.row = index
      this.$refs.filElem1.dispatchEvent(new MouseEvent('click'))
    },
    choiceImg() {
      this.$refs.filElem.dispatchEvent(new MouseEvent('click'))
    },
    getFile1() {
      const _this = this
      const inputFile = this.$refs.filElem1.files[0]
      if(inputFile) {
        if(inputFile.type !== 'image/jpeg' && inputFile.type !== 'image/png' && inputFile.type !== 'image/gif') {
          alert('不是有效的图片文件！')
          return
        }
        const imgInfo = Object.assign({}, this.imgInfo, {
          name: inputFile.name,
          size: inputFile.size,
          lastModifiedDate: inputFile.lastModifiedDate.toLocaleString()
        })
        if(imgInfo.size >= 5 * 1024 * 1024) return this.$message.error('文件不能超过5M')
        console.log(imgInfo)
        const reader = new FileReader()
        reader.readAsDataURL(inputFile)
        reader.onload = function(e) {
          imgInfo.Url = e.target.result
          console.log('imgInfo', imgInfo)
          const notify = _this.$notify
          notify.info({
            title: '文件正在上传中...',
            dangerouslyUseHTMLString: true,
            message: `<span>文件正在上传中</span>`,
            duration: 0
          })
          const obj = document.getElementsByClassName('el-notification__content')[0]
          const load = _this.$loading({
            target: obj,
            text: ''
          })
          _this.$api.OtherInStockUploadAccessory({
            FileExtName: imgInfo.name,
            Base64String: imgInfo.Url
          }).then(res => {
            console.log(res)
            notify.closeAll()
            load.close()
            if(res.RetCode === '0') {
              _this.form.FileUrl = ''
              _this.$notify.success({
                title: '消息',
                message: `附件上传成功，请确认入库`
              })
              _this.form.stockOutDeliveryByArtificials[_this.row].Url = res.Message
              _this.form.stockOutDeliveryByArtificials[_this.row].FileName = imgInfo.name
              console.log('table', _this.form.stockOutDeliveryByArtificials[_this.row])
            }else{
              _this.$notify.error({
                title: '消息',
                message: `附件上传失败`
              })
            }
          })
          _this.$refs.filElem1.value = null
        }
      } else {
        return
      }
    },
    getFile() {
      const _this = this
      const inputFile = this.$refs.filElem.files[0]
      if(inputFile) {
        if(inputFile.type !== 'image/jpeg' && inputFile.type !== 'image/png' && inputFile.type !== 'image/gif') {
          alert('不是有效的图片文件！')
          return
        }
        const imgInfo = Object.assign({}, this.imgInfo, {
          name: inputFile.name,
          size: inputFile.size,
          lastModifiedDate: inputFile.lastModifiedDate.toLocaleString()
        })
        console.log(imgInfo)
        if(imgInfo.size >= 5 * 1024 * 1024) return this.$message.error('文件不能超过5M')
        // eslint-disable-next-line no-unreachable
        const reader = new FileReader()
        reader.readAsDataURL(inputFile)
        reader.onload = function(e) {
          imgInfo.Url = e.target.result
          console.log('imgInfo', imgInfo)
          _this.setUrl(imgInfo)
          this.$refs.filElem.value = null
        }
      } else {
        return
      }
    },
    // 设置url
    setUrl(imgInfo) {
      const notify = this.$notify
      notify.info({
        title: '文件正在上传中...',
        dangerouslyUseHTMLString: true,
        message: `<span>文件正在上传中</span>`,
        duration: 0
      })
      const obj = document.getElementsByClassName('el-notification__content')[0]
      const load = this.$loading({
        target: obj,
        text: ''
      })
      this.$api.OtherInStockUploadAccessory({
        FileExtName: imgInfo.name,
        Base64String: imgInfo.Url
      }).then(res => {
        notify.closeAll()
        load.close()
        console.log(res)
        if(res.RetCode === '0') {
          this.form.FileUrl = ''
          this.$notify.success({
            title: '消息',
            message: `附件上传成功，请确认入库`
          })
          // this.$message.success('上传成功')
          this.form.FileUrl = res.Message // 大附件
        }else{
          this.$notify.error({
            title: '消息',
            message: `附件上传失败`
          })
          // this.$message.error('上传失败')
        }
      })
    }
  }
}

export default Index
